import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Container, 
  Row, 
  Col, 
  Nav, 
  Navbar, 
 
 } from 'react-bootstrap'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import MyAccount from './Layout/MyAccount/MyAccount'
import LoadingScreen from './components/LoadingScreen/LoadingScreen';

function App() {
  const [account, setAccount] = useState({})
  const [loading, setLoading] = useState(true)
  const [loadingText, setLoadingText] = useState('Please Wait...')
  const [secret, setSecret] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    if(secret === null) {
      const _secret = window.prompt('Enter the surname on this account')
      if(_secret === null) {
        setLoadingText('Please try again')
      }
      setSecret(_secret)
  
    }

  },[])

  return (
    <div className="App" >
      {loading ? <LoadingScreen text={loadingText}/> : null}
      <Router>
        <Navbar className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0" bg="primary" variant="dark" expand="md">
          <a className="navbar-brand mr-0 col-sm-3 col-md-2">Amuri.Net</a>
        </Navbar>
        <Container fluid >
          <Row>
            <Col md={2} className="d-none d-md-block bg-light sidebar">
              <div className="sidebar-sticky">
                <Nav>
                  <Link className="nav-link" to="account">My Account</Link>
                  {/* <Link className="nav-link" to="usage">Data Usage</Link> */}
                </Nav>
              </div>
            </Col>
            <Col className="pt-3">
              
              <Switch>
                <Route path='/account/:cid'>
                  <MyAccount secret={secret} error={error} setError={setError} account={account} setAccount={setAccount} loading={loading} setLoading={setLoading} setLoadingText={setLoadingText} />
                </Route>
              </Switch>
              
            </Col>
          </Row>
        </Container>
      </Router>
    </div>
  );
}

export default App;
