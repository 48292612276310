import React, { useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Alert,
    Card,

} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import StripeForm from '../../components/stripeForm'
import superApi from '../../Api/Api'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen'
let stripePromise = null;

switch (process.env.NODE_ENV) {
    case 'development':
        
        // stripePromise = loadStripe('pk_test_LvWef74Se2vTOCyCOBP2Ywcn00zDWrcKdt');
        stripePromise = loadStripe('pk_live_BL3ZYXX3tuWXfXgcUImwhquN002SwclHD4');
        break
    case 'production':
        stripePromise = loadStripe('pk_live_BL3ZYXX3tuWXfXgcUImwhquN002SwclHD4');
        break
    default:
        stripePromise = loadStripe('pk_test_LvWef74Se2vTOCyCOBP2Ywcn00zDWrcKdt');
        
}
const MyAccount = (props) => {

    
    const { cid } = useParams()
    useEffect(() => {
        if(props.secret !== null) {

            superApi.get('/account/' + cid + '/' + props.secret).then(response => {
                props.setAccount(response.data)
                props.setLoading(false)
            })
            .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                //   console.log(error.response.data);
                //   console.log(error.response.status);
                //   console.log(error.response.headers);
                  if (error.response.status === 422) {
                    //   props.setError('Invalid response')
                      props.setLoadingText('Invalid response')
                  }
                } else if (error.request) {
                    props.setLoadingText('Unauthorised')
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                //   console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                //   console.log('Error', error.message);
                props.setLoadingText('Unauthorised')
                }
                // console.log(error.config);
              });
        }
    }, [props.secret])
    if(props.error) {
        return <div>{props.error}</div>
    }
    if (props.loading) {
        return null
    }

    const isOverdue = props.account.billing.customer_balance > 0
    const overdueAmount = props.account.billing.customer_balance
    const nextPayment = props.account.billing.next_payment
    const customerName = props.account.customer.firstname + " " + props.account.customer.lastname
    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className="border-bottom pb-2 mb-4">
                        <h1 className="h2">My Account</h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>{customerName}</Card.Title>
                            <Card.Text>
                                Account Number: {props.account.customer.cardidentification}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    {isOverdue ?
                        <Alert variant="danger">
                            <span>Your account is overdue by ${parseFloat(overdueAmount).toFixed(2)}</span>
                        </Alert> : <Alert variant="info">Your account balance is ${parseFloat(overdueAmount).toFixed(2)}</Alert>}
                    {nextPayment > 0 ? (
                        <React.Fragment>
                            <h1 className="lead">{`Please pay $${nextPayment}`}</h1>
                            <Elements stripe={stripePromise}>

                                <StripeForm cid={cid} secret={props.secret} loading={props.loading} setLoading={props.setLoading} setLoadingText={props.setLoadingText}/>
                            </Elements>
                        </React.Fragment>
                    ) : null}
                </Col>
            </Row>
            <Row>
                <Col md={6}>

                </Col>
            </Row>
        </Container>
    );
}

export default MyAccount;