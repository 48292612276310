import axios from 'axios';

const superApi = axios.create({

})

switch (process.env.NODE_ENV) {
    case 'development':
        // superApi.defaults.baseURL = 'http://localhost:5000/'
        superApi.defaults.baseURL = 'https://jomola.amuri.net/'
        
        break
    default:
        superApi.defaults.baseURL = 'https://jomola.amuri.net/'
        
}

if (process.env.REACT_APP_TEST === 'true') {
    // Test environment //
}

// superApi.defaults.auth = {
//     username: window.localStorage.getItem('authBody'),
//     password: ''
// }

// superApi.interceptors.response.use((response) => {
//     return response;
// }, (error) => {
//     // Handle 401 etc.
//     if (error.response && error.response.status === 401) {
//         window.localStorage.removeItem('auth');
//         window.localStorage.setItem('expired', true);
//         window.localStorage.removeItem('authBody');
//     }

//     return Promise.reject(error)
// })

export default superApi;