import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import styles from './LoadingScreen.module.css'

const LoadingScreen = (props) => {
    return (
        <React.Fragment>
            <div className={styles.text}>
                <h4>{props.text}</h4>
            </div>
            <div className={styles.container}>
            </div>
        </React.Fragment>
    );

}


export default LoadingScreen;