import React, { useState } from 'react';
import {
    InputGroup,
    Button
} from 'react-bootstrap'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import superApi from '../Api/Api'
import LoadingScreen from '../components/LoadingScreen/LoadingScreen'

const StripeForm = (props) => {
    const elements = useElements()
    const stripe = useStripe()
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState("")
    const handleSubmit = async (event) => {
        setLoading(true)
        setLoadingText('Requesting...')
        event.preventDefault()
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setLoadingText('Processing...')
        superApi.post('/stripe/'+props.secret, {'cid': props.cid}).then(response => {
            const result = stripe.confirmCardPayment(response.data.client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: props.cid,
                    },
                }
            });
            result.then(result => {
                
                if (result.error) {
                    setLoadingText(result.error.message)
                } else {
                    if (result.paymentIntent.status === 'succeeded') {
                        setLoadingText('Success! Payment received, thanks')
                        // Tell jomola
                        // alert('success')
                    } else {
                        props.setLoadingText("Something went wrong with this card, please call merchant or try a different card")
                    }
                }
            })
        })
        .catch(function (error) {
            if (error.response) {
              if(error.response.message) {
                  props.setLoadingText(error.response.message)
              } else {
                  props.setLoadingText("Something went wrong with this card, please call merchant or try a different card")
              }
            } else {
                props.setLoadingText("Something went wrong with this card, please call merchant or try a different card")
            }
             
            // console.log(error.config);
          });
        const cardElement = elements.getElement(CardElement);
        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setLoadingText(error.message);
        } else {
            // console.log('[PaymentMethod]', paymentMethod);
        }
    };

    const cardElementOptions = {
        hidePostalCode: true,
        style: {
            base: {
                fontSize: '16px',
                color: '#4247700',
                '::placeholder': {
                    color: '#aab7c4'
                },
            },
            invalid: {
                color: '#9e2146'
            },
        },
    }
    return (
        <React.Fragment>
        <form onSubmit={handleSubmit}>
                <InputGroup className="my-3 d-flex" size="lg">

                    <CardElement className="form-control pt-3" options={cardElementOptions} />
                    <InputGroup.Append>
                        <Button disabled={!stripe} type="submit">Pay</Button>
                    </InputGroup.Append>
                </InputGroup>
            </form>
        {loading ? <LoadingScreen text={loadingText} /> : null}
        </React.Fragment>
    );
}

export default StripeForm;